<template>
    <div class="cart">
        <h3>Introduction</h3>
        <h6>简介</h6>
        <div class="clearfix">
            <div class="fl" style="width: 742px;margin-right: 90px;height: 100%;overflow: auto;">
                <p class="pText">重庆安标检测研究院有限公司（以下简称“重庆安标检测”）始建于1965年，于2013年注册成立法人机构，隶属于中央企业中煤科工集团重庆研究院有限公司。重庆安标检测在一个资源平台上建有国家煤矿防尘通风安全产品质量监督检验中心、国家矿山安全计量站、国家安全生产重庆矿用设备检测检验中心等七个机构，是经原国家技术监督局、国家煤矿安全监察局批准、授权成立的国家级检验机构和国家级法定计量检定机构。</p>
                <p class="pText">历经半个世纪的建设和发展，现已发展成为集检验检测、计量、标准制修订、培训、科研及技术服务于一体的综合性检测技术服务机构。重庆安标检测下设办公室、业务管理部、质量监督部、市场部四个职能部门和仪器仪表检测研究所、安全装备检测研究所、防爆检测研究所、电器与传动检测研究所、防隔爆设施检测研究所五个检测所以及一个检测技术研究所；可承担产品质量监督抽查检验、安全标志产品检验、防爆电气产品强制性认证（CCC）检验、计量器具型式评价、委托检验、事故调查检验、仲裁检验、设备防爆安全检验、节能认证检验、进货验收检验、在用产品检验、计量检定及校准等任务；同时可开展检定及检测人员技术培训，计量检测技术和计量标准装置研究等工作。</p>
                <p class="pText">重庆安标检测坚定不移持续推进科技创新，先后主持或参与国家省部级课题30余项，制/修订国标、行标、计量规范80余项；安标检测研究院是煤炭行业煤矿安全标委员会通风技术及设备分会的主任委员及秘书长单位，是全国环境化学计量技术委员会、全国防爆电气标准化委员会、全国粉尘防爆标准化委员会等11个专业技术组织的委员单位。</p>
                <p class="pText">重庆安标检测先后与DNV GL签署国际认证合作协议，与CQC、CQM、CNEX、PCEC等6家强制性产品认证机构签署合作协议；与美、南非等多国保持着长期的科技合作与交流，并与国内各大煤企、高校合作开展检测技术研究。</p>
                <p class="pText">在中国煤科和重庆研究院的正确领导下,重庆安标检测将以饱满昂扬的斗志 ,改革创新的姿态,紧紧围绕总体战略规划,坚持安全检测主业发展方向 ,努力成长为“国内领先、国际一流的综合性检测技术服务机构”，推动行业技术发展进步！</p>
            </div>
            <img width="320" height="440" src="~@/assets/home.png" style="border-radius: 17px;"/>
        </div>
    </div>
</template>
<script>
export default {
    name: 'home'
}
</script>